import { render, staticRenderFns } from "./DataImport.vue?vue&type=template&id=0efb6adf&scoped=true&"
import script from "./DataImport.vue?vue&type=script&lang=js&"
export * from "./DataImport.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0efb6adf",
  null
  
)

export default component.exports